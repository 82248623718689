@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/f98b2cfc6c92cc2a-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 150;
font-style: normal;
}

@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/9477c553a010bec6-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 150;
font-style: italic;
}

@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/e11c08beae399a13-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/959f42117a1efbc9-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/7b2db5e2d988532d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 350;
font-style: normal;
}

@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/13848045fddefa6a-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 350;
font-style: italic;
}

@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/15fd69ca95868dd4-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/c80e1105d5bf1526-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/59157131e47a16b3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__localFonts_74d5f3';
src: url(/_next/static/media/59157131e47a16b3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: italic;
}@font-face {font-family: '__localFonts_Fallback_74d5f3';src: local("Arial");ascent-override: 77.58%;descent-override: 15.71%;line-gap-override: 0.00%;size-adjust: 128.64%
}.__className_74d5f3 {font-family: '__localFonts_74d5f3', '__localFonts_Fallback_74d5f3'
}.__variable_74d5f3 {--font-website: '__localFonts_74d5f3', '__localFonts_Fallback_74d5f3'
}

